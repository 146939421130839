import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import { disconnect } from "@wagmi/core";
import { injectModels } from "../../Redux/injectModels";
import Swal from "sweetalert2";
import toast from "react-hot-toast";
import { useAccount } from "wagmi";

const Sidebar = (props) => {
  const history = useHistory();
  const location = useLocation();
  const isMobile = window.innerWidth <= 768;
  const { address, isConnecting, isDisconnected } = useAccount({
    onConnect({ address, connector, isReconnected }) {},
    onDisconnect() {
      window.location.reload();
    },
  });

  const handleRedirect = async () => {
    Swal.fire({
      background: "#001420",
      title: "Confirmation",
      text: "Are you sure you want to Disconnect?",
      showCancelButton: true,
      confirmButtonColor: "#12b6b3",
      cancelButtonColor: "#373737",
      confirmButtonText: `Yes`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          props.application.setLoading(true);
          localStorage.clear();
          await disconnect();
          localStorage.clear();
          handleDashboardRedirect();
          props.application.setLoading(false);
          toast.success("Logout successfull");
        } catch (err) {
          props.application.setLoading(false);
          console.log(err);
        }
      }
    });
  };

  const handleItemClick = () => {
    if (isMobile && props.isOpenState) {
      props.handleClick();
    } else {
      props.handleClick();
    }
  };

  const handleItemClickDesktop = () => {
    if (isMobile && props.isOpenState) {
      props.handleClick();
    }
  };

  const handleDashboardRedirect = () => {
    history.push("/");
  };

  const getNotificationData = async () => {
    try {
      props.application.setLoading(true);
      const response = await props.admin.getUserNotificationDetail(address);
      if (response.status === 201) {
        props.application.setLoading(false);
      } else {
        props.application.setLoading(false);
      }
    } catch (error) {
      console.error(error);
      props.application.setLoading(false);
    }
  };

  useEffect(async () => {
    getNotificationData();
  }, [address]);

  return (
    <React.Fragment>
      {isMobile === false && (
        <section className="sidebar-menu">
          <div className="row">
            <div className="col-md-12">
              <div className="sidebar-menu-list">
                <div className="close-btn">
                  <button onClick={props.handleClick}>
                    <i className="fa-solid fa-xmark" />
                  </button>
                </div>
                <div
                  className="das-nakamoto-logo"
                  onClick={handleDashboardRedirect}
                >
                  <img
                    src="/assets/img/nakamoto-logo-left.png"
                    className="small-img"
                    alt="logo"
                  />

                  <h1 className="large-img">Naka Token</h1>
                </div>

                <ul>
                  <li
                    className={
                      location.pathname === "/dashboard" ||
                      location.pathname === "/dashboard/edit-profile"
                        ? "active"
                        : ""
                    }
                  >
                    {" "}
                    <Link to="/dashboard" onClick={handleItemClickDesktop}>
                      {" "}
                      <img
                        src="/assets/img/user.svg"
                        className="img-fluid"
                        alt="user"
                      />
                      <span> Profile </span>
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/dashboard/wallet" ? "active" : ""
                    }
                  >
                    {" "}
                    <Link
                      to="/dashboard/wallet"
                      onClick={handleItemClickDesktop}
                    >
                      {" "}
                      <img
                        src="/assets/img/wallet.svg"
                        className="img-fluid"
                        alt="user"
                      />
                      <span> Wallet</span>
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/dashboard/timeline"
                        ? "active"
                        : ""
                    }
                  >
                    {" "}
                    <Link
                      to="/dashboard/timeline"
                      onClick={handleItemClickDesktop}
                    >
                      {" "}
                      <img
                        src="/assets/img/timeline.svg"
                        className="img-fluid"
                        alt="user"
                      />
                      <span> Timeline </span>
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/dashboard/notification"
                        ? "active"
                        : ""
                    }
                  >
                    {" "}
                    <Link
                      to="/dashboard/notification"
                      className="notf"
                      onClick={handleItemClickDesktop}
                    >
                      {props.admin.notificationCount == 0 ? (
                        ""
                      ) : (
                        <div className="notification-value">
                          {props.admin.notificationCount}
                        </div>
                      )}

                      <img
                        src="/assets/img/notification.svg"
                        className="img-fluid"
                        alt="notification"
                      />

                      <span> Notifications </span>
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/dashboard/network" ? "active" : ""
                    }
                  >
                    {" "}
                    <Link
                      to="/dashboard/network"
                      onClick={handleItemClickDesktop}
                    >
                      {" "}
                      <img
                        src="/assets/img/network.svg"
                        className="img-fluid"
                        alt="network"
                      />
                      <span> Network </span>
                    </Link>
                  </li>

                  <li
                    className={
                      location.pathname === "/dashboard/investments"
                        ? "active"
                        : ""
                    }
                  >
                    {" "}
                    <Link
                      to="/dashboard/investments"
                      onClick={handleItemClickDesktop}
                    >
                      {" "}
                      <img
                        src="/assets/img/investment.svg"
                        className="img-fluid"
                        alt="network"
                      />
                      <span> Naka Withdrawals </span>
                    </Link>
                  </li>

                  <li
                    className={
                      location.pathname === "/dashboard/report" ? "active" : ""
                    }
                  >
                    {" "}
                    <Link
                      to="/dashboard/report"
                      onClick={handleItemClickDesktop}
                    >
                      {" "}
                      <img
                        src="/assets/img/bar-chart.svg"
                        className="img-fluid"
                        alt="user"
                      />
                      <span> Report </span>
                    </Link>
                  </li>

                  <li
                    className={
                      location.pathname === "/dashboard/tutorial"
                        ? "active"
                        : ""
                    }
                  >
                    {" "}
                    <Link
                      to="/dashboard/tutorial"
                      onClick={handleItemClickDesktop}
                    >
                      {" "}
                      <img
                        src="/assets/img/tutorial.svg"
                        className="img-fluid"
                        alt="tutorial"
                      />
                      <span> Tutorial </span>
                    </Link>
                  </li>
                </ul>
                <div className="logout">
                  <div className="logout-img">
                    <Link to="#" onClick={handleRedirect}>
                      <img
                        src="/assets/img/logout.svg"
                        className="img-fluid"
                        alt="logut"
                      />
                      <h6>Logout</h6>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {isMobile && (
        <section className="sidebar-menu">
          <div className="row">
            <div className="col-md-12">
              <div className="sidebar-menu-list">
                <div className="close-btn">
                  <button onClick={props.handleClick}>
                    <i className="fa-solid fa-xmark" />
                  </button>
                </div>
                <div
                  className="das-nakamoto-logo"
                  onClick={handleDashboardRedirect}
                >
                  <img
                    src="/assets/img/nakamoto-logo-left.png"
                    className="small-img"
                    alt="logo"
                  />

                  <h1 className="large-img">Naka Token</h1>
                </div>
                <ul>
                  <li
                    className={
                      location.pathname === "/dashboard" ||
                      location.pathname === "/dashboard/edit-profile"
                        ? "active"
                        : ""
                    }
                    onClick={props.isOpenState ? handleItemClick : ""}
                  >
                    {" "}
                    <Link to="/dashboard">
                      {" "}
                      <img
                        src="/assets/img/user.svg"
                        className="img-fluid"
                        alt="user"
                      />
                      <span> Profile </span>
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/dashboard/wallet" ? "active" : ""
                    }
                    onClick={props.isOpenState ? handleItemClick : ""}
                  >
                    {" "}
                    <Link to="/dashboard/wallet">
                      {" "}
                      <img
                        src="/assets/img/wallet.svg"
                        className="img-fluid"
                        alt="user"
                      />
                      <span> Wallet</span>
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/dashboard/timeline"
                        ? "active"
                        : ""
                    }
                    onClick={props.isOpenState ? handleItemClick : ""}
                  >
                    {" "}
                    <Link to="/dashboard/timeline">
                      {" "}
                      <img
                        src="/assets/img/timeline.svg"
                        className="img-fluid"
                        alt="user"
                      />
                      <span> Timeline </span>
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/dashboard/notification"
                        ? "active"
                        : ""
                    }
                    onClick={props.isOpenState ? handleItemClick : ""}
                  >
                    {" "}
                    <Link to="/dashboard/notification" className="notf">
                      {props.admin.notificationCount == 0 ? (
                        ""
                      ) : (
                        <div className="notification-value">
                          {props.admin.notificationCount}
                        </div>
                      )}

                      <img
                        src="/assets/img/notification.svg"
                        className="img-fluid"
                        alt="notification"
                      />

                      <span> Notifications </span>
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/dashboard/network" ? "active" : ""
                    }
                    onClick={props.isOpenState ? handleItemClick : ""}
                  >
                    {" "}
                    <Link to="/dashboard/network">
                      {" "}
                      <img
                        src="/assets/img/network.svg"
                        className="img-fluid"
                        alt="network"
                      />
                      <span> Network </span>
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/dashboard/investments"
                        ? "active"
                        : ""
                    }
                  >
                    {" "}
                    <Link
                      to="/dashboard/investments"
                      onClick={handleItemClickDesktop}
                    >
                      {" "}
                      <img
                        src="/assets/img/investment.svg"
                        className="img-fluid"
                        alt="network"
                      />
                      <span> Naka Withdrawals </span>
                    </Link>
                  </li>

                  <li
                    className={
                      location.pathname === "/dashboard/report" ? "active" : ""
                    }
                    onClick={props.isOpenState ? handleItemClick : ""}
                  >
                    {" "}
                    <Link to="/dashboard/report">
                      {" "}
                      <img
                        src="/assets/img/bar-chart.svg"
                        className="img-fluid"
                        alt="Report"
                      />
                      <span> Report </span>
                    </Link>
                  </li>

                  <li
                    className={
                      location.pathname === "/dashboard/tutorial"
                        ? "active"
                        : ""
                    }
                    onClick={props.isOpenState ? handleItemClick : ""}
                  >
                    {" "}
                    <Link to="/dashboard/tutorial">
                      {" "}
                      <img
                        src="/assets/img/tutorial.svg"
                        className="img-fluid"
                        alt="tutorial"
                      />
                      <span> Tutorial </span>
                    </Link>
                  </li>
                </ul>

                <div className="logout">
                  <div className="logout-img">
                    <Link to="#" onClick={handleRedirect}>
                      <img
                        src="/assets/img/logout.svg"
                        className="img-fluid"
                        alt="logut"
                      />
                      <h6>Logout</h6>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </React.Fragment>
  );
};

export default injectModels(["admin", "application"])(Sidebar);
