import React, { useEffect, useState } from "react";
import { injectModels } from "../Redux/injectModels";
import { getPrice } from "../components/uinswap_v3_price/uinswapbot";
import Lottie from "lottie-react";
import loadingAnimation from "../animation/loading.json";

const Header1 = (props) => {
  const [price, setPrice] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const startTimeStamp = 1712341800;

  const totalUsersAddedInOneDay = 24 * 12; //Users Added in One Day
  const totalProfitGenratedInOneDay = 24 * 12 * 40; //Profit Added in One Day

  const lastUsers = 502042;
  const lastProfit = 27635780;

  const ONEDAY = 24 * 60 * 60;

  const rateOfUsersGrowth = totalUsersAddedInOneDay / ONEDAY; //80 users per 24 hours
  const rateOfProfitGrowth = totalProfitGenratedInOneDay / ONEDAY; //8000 profit per 24 hours

  const [count, setCount] = useState(() => {
    const currentTime = Math.floor(Date.now() / 1000);
    const extraTime = currentTime - startTimeStamp;
    const extraUsers = Math.floor(extraTime * rateOfUsersGrowth);
    const todaysExtraTime = extraTime - Math.floor(extraTime / ONEDAY) * ONEDAY;
    const todaysUsers = Math.floor(todaysExtraTime * rateOfUsersGrowth);
    const totalUsers = lastUsers + extraUsers;
    return { totalUsers: totalUsers, todaysUsers: todaysUsers };
  });
  const [totalProfit, setTotalProfit] = useState(() => {
    const currentTime = Math.floor(Date.now() / 1000);
    const extraTime = currentTime - startTimeStamp;
    const extraProfit = Math.floor(extraTime * rateOfProfitGrowth);
    const todaysExtraTime = extraTime - Math.floor(extraTime / ONEDAY) * ONEDAY;
    const todaysProfit = Math.floor(todaysExtraTime * rateOfProfitGrowth);
    const totalProfit = lastProfit + extraProfit;
    return { totalProfit: totalProfit, todaysProfit: todaysProfit };
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount(() => {
        const currentTime = Math.floor(Date.now() / 1000);
        const extraTime = currentTime - startTimeStamp;
        const extraUsers = Math.floor(extraTime * rateOfUsersGrowth);
        const todaysExtraTime =
          extraTime - Math.floor(extraTime / ONEDAY) * ONEDAY;
        const todaysUsers = Math.floor(todaysExtraTime * rateOfUsersGrowth);
        const totalUsers = lastUsers + extraUsers;
        return { totalUsers: totalUsers, todaysUsers: todaysUsers };
      });
      setTotalProfit(() => {
        const currentTime = Math.floor(Date.now() / 1000);
        const extraTime = currentTime - startTimeStamp;
        const extraProfit = Math.floor(extraTime * rateOfProfitGrowth);
        const todaysExtraTime =
          extraTime - Math.floor(extraTime / ONEDAY) * ONEDAY;
        const todaysProfit = Math.floor(todaysExtraTime * rateOfProfitGrowth);
        const totalProfit = lastProfit + extraProfit;
        return { totalProfit: totalProfit, todaysProfit: todaysProfit };
      });
      getTokenPrice();
    }, 10000);
    return () => clearInterval(intervalId);
  }, []);

  const INFURA_KEYS = [
    "00e770be1f414e4a8d3ae65866b8bb72",
    "e5c7daebe3854099bb72ed3fd6814cbc",
    "4f069fb0c2dc49b7af1770d45c521240",
    "db35afb7fa5b4454b7f159f24c0411ef",
  ];

  const getInfuraUrl = () => {
    const currentHour = new Date().getHours(); // Get the current hour (0 to 23)
    const keyIndex = Math.floor(currentHour / 6); // Divide the day into 4 segments of 6 hours
    const selectedApiKey = INFURA_KEYS[keyIndex]; // Select the appropriate API key

    return `https://polygon-mainnet.infura.io/v3/${selectedApiKey}`; // Return full Infura URL with API key
  };

  const getTokenPrice = async () => {
    if (isLoading) {
      return;
    }

    try {
      setIsLoading(true);

      const infuraUrl = getInfuraUrl();
      console.log(infuraUrl, "infuraUrl");
      const price = await getPrice(
        1, //Amount of token 1
        infuraUrl, // RPC url
        "WQGDRA3YZDMSFEUVUKRSEDZS3FGGTMCG6W", // Block Scan Api Key
        "0x1B8C03343473Bc648c1b82c85C1DD32C9d63430e", // Pool Address
        "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6" // Quoter Address
      );
      setPrice(Number(1 / Number(price)));
      setIsLoading(false);
    } catch (ex) {
      setIsLoading(false);
      console.log(ex, "error");
    }
  };

  return (
    <React.Fragment>
      <section className="header">
        <div className="container">
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-6 ">
              <div className="total-join">
                <h6>
                  {" "}
                  <small> Total &nbsp;</small> Joined -&nbsp;&nbsp;
                  <span>{count.totalUsers}</span>
                </h6>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12 order-3 order-md-2">
              <div className="prifit-gen">
                <button>
                  {" "}
                  Profit Generated -{" "}
                  <div className="usdt-logo">
                    <img
                      src="/assets/img/usdt.svg"
                      className="img-fluid"
                      alt="usdt"
                    />
                  </div>
                  <span>{totalProfit.totalProfit}</span>
                </button>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-6 order-2">
              <div className="total-join-usdt">
                {(isLoading || price === null) && (
                  <Lottie
                    style={{ height: 32, margin: 0 }}
                    animationData={loadingAnimation}
                    loop={true}
                  />
                )}
                {!(isLoading || price === null) && (
                  <div className="usdt-logo">
                    <img
                      src="/assets/img/usdt.svg"
                      className="img-fluid"
                      alt="usdt"
                    />
                  </div>
                )}

                <h6>{`USDT 1=${
                  price === null ? "Fetching.." : Number(price).toFixed(3)
                } NKT`}</h6>
                {
                  <div className="logo-naka">
                    <span>
                      <img
                        src="/assets/img/logo.svg"
                        className="img-fluid"
                        alt="nakalogo"
                      />
                    </span>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default injectModels(["admin", "application"])(Header1);
